import React from "react";

const FooterIcon = () => {
  return (
    <svg
      width="656"
      height="402"
      viewBox="0 0 656 402"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <mask id="path-1-inside-1_0_1" fill="white">
          <path d="M794.991 308.616L617 0L350 463H527.991L617.106 308.705L706.009 463H884L794.885 308.705" />
        </mask>
        <path
          d="M617 0L617.567 -0.326905L617 -1.30976L616.433 -0.326878L617 0ZM350 463L349.433 462.673L348.867 463.654H350V463ZM527.991 463V463.654H528.369L528.558 463.327L527.991 463ZM617.106 308.705L617.673 308.378L617.107 307.396L616.54 308.378L617.106 308.705ZM706.009 463L705.442 463.327L705.631 463.654H706.009V463ZM884 463V463.654H885.134L884.567 462.673L884 463ZM795.558 308.29L617.567 -0.326905L616.433 0.326905L794.424 308.943L795.558 308.29ZM616.433 -0.326878L349.433 462.673L350.567 463.327L617.567 0.326878L616.433 -0.326878ZM350 463.654H527.991V462.346H350V463.654ZM528.558 463.327L617.673 309.032L616.54 308.378L527.425 462.673L528.558 463.327ZM616.539 309.032L705.442 463.327L706.576 462.673L617.673 308.378L616.539 309.032ZM706.009 463.654H884V462.346H706.009V463.654ZM884.567 462.673L795.452 308.378L794.318 309.032L883.433 463.327L884.567 462.673Z"
          fill="white"
          mask="url(#path-1-inside-1_0_1)"
        />
      </g>
      <path
        opacity="0.7"
        d="M348.189 11.3272H521.433L260.736 462.673H87.4915L174.283 312.426L174.378 312.262L174.283 312.099L0.566775 11.3272H173.811L260.641 161.901L260.924 162.392L261.208 161.901L348.189 11.3272Z"
        stroke="white"
        strokeWidth="0.654331"
      />
    </svg>
  );
};

export default FooterIcon;
