import React from "react";

const TwitterIcon = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18.5" cy="18.5" r="18.5" fill="white" />
      <path
        d="M29.46 13C28.69 13.35 27.86 13.58 27 13.69C27.88 13.16 28.56 12.32 28.88 11.31C28.05 11.81 27.13 12.16 26.16 12.36C25.37 11.5 24.26 11 23 11C20.65 11 18.73 12.92 18.73 15.29C18.73 15.63 18.77 15.96 18.84 16.27C15.28 16.09 12.11 14.38 10 11.79C9.63004 12.42 9.42004 13.16 9.42004 13.94C9.42004 15.43 10.17 16.75 11.33 17.5C10.62 17.5 9.96004 17.3 9.38004 17V17.03C9.38004 19.11 10.86 20.85 12.82 21.24C12.1908 21.4122 11.5301 21.4362 10.89 21.31C11.1616 22.1625 11.6936 22.9084 12.4111 23.4429C13.1285 23.9775 13.9955 24.2737 14.89 24.29C13.3737 25.4904 11.494 26.1393 9.56004 26.13C9.22004 26.13 8.88004 26.11 8.54004 26.07C10.44 27.29 12.7 28 15.12 28C23 28 27.33 21.46 27.33 15.79C27.33 15.6 27.33 15.42 27.32 15.23C28.16 14.63 28.88 13.87 29.46 13Z"
        fill="#6FA935"
      />
    </svg>
  );
};

export default TwitterIcon;
