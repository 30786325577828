import styled from "@emotion/styled"
import { mobile } from "../../styles/media"

const SVGContainer = styled.div`
    svg{
      width: ${({ width }) => width};
      height: ${({ height }) => height};
      ${({mobileHeight, mobileWidth, width, height}) => mobile(`
        width: ${mobileWidth ? mobileWidth : width};
        height: ${mobileHeight ? mobileHeight : height};
      `)} 
      cursor: ${({ cursor }) => cursor ? cursor : "default"};
      fill: ${({ color }) => color}!important;
      display: flex;
      justify-content: center;
      align-items: center;
      path{
        stroke: ${({ stockColor }) => stockColor};
        fill: ${({ color }) => color};
      }
    }
    &:hover{
      fill : ${({ hoverColor }) => hoverColor ? hoverColor : ""};
    }
  `

const SVG = ({
  onClick,
  width,
  height,
  color,
  cursor,
  mobileWidth,
  mobileHeight,
  stockColor,
  icon,
  hoverColor
}) => {
  return (
    <SVGContainer
    hoverColor={hoverColor}
      width={width}
      height={height}
      color={color}
      cursor={cursor}
      onClick={onClick}
      stockColor={stockColor}
      mobileWidth={mobileWidth}
      mobileHeight={mobileHeight}
    >
      {icon}
    </SVGContainer>
  )
}

export default SVG