import styled from '@emotion/styled'
import React from 'react'
import { mobile } from '../../../../styles/media'

const ItemContainer = styled.div`
    width: 200px;
    padding: 1rem;
    background-color: ${({theme}) => theme.colors.white};
    box-shadow: ${({theme}) => theme.boxShadow.primary};
    border-radius: ${({theme}) => theme.boarderRadius.md};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    ${mobile(`
      width: 100%;
    `)}
`

const Item = ({text, title}) => {
  return (
    <ItemContainer>
        <h3>{title}</h3>
        <p>{text}</p>
    </ItemContainer>
  )
}

export default Item