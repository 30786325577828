import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";
import SVG from "../../../common/SVG";
import RightArrowIcon from "../../../icons/RightArrowIcon";
import InfoDecorationPoints from "../../../icons/InfoDecorationPoints";
import { useTheme } from "@emotion/react";
import Item from "./Item";
import { mobile } from "../../../styles/media";

const BottomInfoSectionContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 2rem;
  padding: 4rem 10% 0 10%;
  
  ${mobile(`
    gap: 0;
    flex-direction: column;
    padding: 4rem 10% 4rem 10%;
  `)}
  .left-side {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 400px;
    position: relative;
    ${mobile(`
      width: 100%;
    `)}
    .decoration-line {
      position: absolute;
      left: -40px; /* Adjust this value as needed */
      top: 0;
      z-index: -1;
    }
    .right-piece {
      padding-left: 2rem;
      position: relative;
    }
    .left-piece {
      position: relative;
          ${mobile(`
      width: 100% !important;
    `)}
    }
  }
  .right-side {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    ${mobile(`
    width: 100%;
`)}
    h1 {
      display: flex;
      align-items: center;
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      font-size: ${({ theme }) => theme.fontSizes.xxxxl};
      color: ${({ theme }) => theme.colors.text};
    }
    p {
      font-weight: ${({ theme }) => theme.fontWeights.light};
      font-size: ${({ theme }) => theme.fontSizes.md};
      color: ${({ theme }) => theme.colors.text};
    }
    a {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.text};
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      font-size: ${({ theme }) => theme.fontSizes.md};
    }
  }
`;

const BottomInfoSection = () => {
  const theme = useTheme();
  return (
    <BottomInfoSectionContainer>
      <div className="left-side">
        <div className="decoration-line">
          <SVG
            width="80px"
            height="320px"
            icon={<InfoDecorationPoints />}
            color={theme.colors.text}
          />
        </div>
        <div className="left-piece">
          <Item label="Create an account ..." />
        </div>
        <div className="right-piece">
          <span>Create an account ...</span>
        </div>
        <div className="left-piece">
          <Item label="Select account type" />
        </div>
        <div className="right-piece">
          <span>Create an account ...</span>
        </div>
        <div className="right-piece">
          <Item label="Courses" />
        </div>
        <div className="right-piece">
          <Item label="Courses" />
        </div>
      </div>
      <div className="right-side">
        <h1>
          <span>How it works</span>
        </h1>
        <p>
          <b>At Young Africa,</b> we are committed to revolutionizing the job
          search experience for Africans. Here's why thousands of job seekers
          trust us to help them find their next opportunity:
        </p>
        <Link to="/jobs">
          <span>Read more about us</span>
          <SVG width="17px" height="17px" icon={<RightArrowIcon />} />
        </Link>
      </div>
    </BottomInfoSectionContainer>
  );
};

export default BottomInfoSection;
