export const mobile = (styles) => `
  @media (max-width: 767px) {
    ${styles}
  }
`;

export const tablet = (styles) => `
  @media (min-width: 768px) and (max-width: 1024px) {
    ${styles}
  }
`;

export const desktop = (styles ) => `
  @media (min-width: 1024px) and (max-width: 1300px) {
    ${styles}
  }
`;

export const desktopLarge = (styles ) => `
  @media (min-width: 1301px) {
    ${styles}
  }
`;