import { Global, css } from "@emotion/react";

import SatoshiRegular from "../../assets/Fonts/OTF/Satoshi-Regular.otf";
import SatoshiMedium from "../../assets/Fonts/OTF/Satoshi-Medium.otf";
import SatoshiBold from "../../assets/Fonts/OTF/Satoshi-Bold.otf";
import TacoandTequilaRegular from "../../assets/Fonts/OTF/TacoandTequila-Regular.otf";

const GlobalStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: "Satoshi-Regular";
        src: url(${SatoshiRegular}) format("opentype");
      }

      @font-face {
        font-family: "Satoshi-Medium";
        src: url(${SatoshiMedium}) format("opentype");
      }

      @font-face {
        font-family: "Satoshi-Bold";
        src: url(${SatoshiBold}) format("opentype");
        font-weight: bold; /* Ensure the correct font-weight declaration */
      }

      @font-face {
        font-family: "TacoandTequila-Regular";
        src: url(${TacoandTequilaRegular}) format("opentype");
      }

      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      body {
        font-family: "Satoshi-Regular", "Satoshi-Medium", "Satoshi-Bold"; /* Use the custom font */
      }

      a {
        text-decoration: none;
      }
    `}
  />
);

export default GlobalStyles;
