import React from "react";

const LogoIcon = () => {
  return (
    <svg
      width="230"
      height="41"
      viewBox="0 0 230 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_38_4)">
        <path
          d="M97.251 10.8844L93.0692 18.6254C92.877 18.1927 92.7078 17.8068 92.4428 17.3251L88.9564 10.8844H85.4937L91.4657 21.5949L87.6898 28.5847H90.7873L100.617 10.8844H97.2518H97.251Z"
          fill="#6FA935"
        />
        <path
          d="M105.315 16.2836C101.61 16.2836 98.9221 18.8504 98.9221 22.5796C98.9221 26.3088 101.61 28.8748 105.315 28.8748C109.019 28.8748 111.707 26.308 111.707 22.5796C111.707 18.8511 109.019 16.2836 105.315 16.2836ZM105.315 26.2353C103.281 26.2353 101.876 24.7581 101.876 22.5788C101.876 20.3995 103.281 18.9223 105.315 18.9223C107.348 18.9223 108.753 20.3995 108.753 22.5788C108.753 24.7581 107.348 26.2353 105.315 26.2353Z"
          fill="#6FA935"
        />
        <path
          d="M122.069 22.8944C122.069 25.1702 121.173 26.2599 119.309 26.2599C117.662 26.2599 116.766 25.3395 116.766 23.0637V16.6229H113.812V24.1051C113.812 27.0348 115.435 28.8994 118.194 28.8994C119.793 28.8994 121.342 28.1245 122.068 26.9866L122.287 28.5847H125.023V16.6229H122.068V22.8944H122.069Z"
          fill="#6FA935"
        />
        <path
          d="M135.07 16.26C133.447 16.26 131.995 16.9622 131.244 18.1729L131.002 16.623H128.266V28.5848H131.221V22.144C131.221 20.2067 132.334 18.9478 134.15 18.9478C135.676 18.9478 136.668 19.9165 136.668 21.9258V28.5848H139.622V21.2236C139.622 18.1238 138.073 16.26 135.07 16.26Z"
          fill="#6FA935"
        />
        <path
          d="M151.63 18.2693C150.928 16.9859 149.523 16.2355 147.732 16.2355C144.318 16.2355 142.041 18.8505 142.041 22.3377C142.041 25.825 144.269 28.3428 147.659 28.3428C149.378 28.3428 150.855 27.6406 151.605 26.5272V28.5127C151.605 30.4983 150.298 31.7817 148.264 31.7817C146.447 31.7817 145.261 30.8613 145.067 29.3122H142.113C142.476 32.5084 144.826 34.4695 148.264 34.4695C152.138 34.4695 154.535 31.9755 154.535 27.9798V16.6237H151.799L151.63 18.2701V18.2693ZM148.24 25.776C146.303 25.776 145.019 24.2743 145.019 22.265C145.019 20.2557 146.278 18.8268 148.24 18.8268C150.298 18.8268 151.533 20.2312 151.533 22.265C151.533 24.2988 150.275 25.776 148.24 25.776Z"
          fill="#6FA935"
        />
        <path
          d="M168.965 10.8844L162.573 28.5847H165.794L167.222 24.4925L168.143 21.8775L170.153 16.1627C170.323 15.6788 170.468 15.1458 170.54 14.8066C170.589 15.1695 170.758 15.7025 170.903 16.1627L172.937 21.8775L173.833 24.4925L175.262 28.5847H178.531L172.139 10.8844H168.967H168.965Z"
          fill="#6FA935"
        />
        <path
          d="M180.707 15.1703V16.623H178.988V19.0925H180.707V28.5847H183.637V19.0925H186.494V16.623H183.637V15.1703C183.637 13.9842 184 13.2092 185.502 13.2092H186.543V10.6907C186.034 10.5942 185.477 10.5697 185.018 10.5697C182.427 10.5697 180.707 11.9258 180.707 15.1703Z"
          fill="#6FA935"
        />
        <path
          d="M191.529 18.3903L191.359 16.6467H188.574V28.584H191.529V22.7727C191.529 20.4479 192.86 19.31 194.991 19.31H196.081V16.5739C195.597 16.4529 195.185 16.4039 194.773 16.4039C193.175 16.4039 192.037 17.2034 191.529 18.3895V18.3903Z"
          fill="#6FA935"
        />
        <path
          d="M199.76 10.6661C198.743 10.6661 197.943 11.4411 197.943 12.4335C197.943 13.4259 198.743 14.2253 199.76 14.2253C200.777 14.2253 201.527 13.4259 201.527 12.4335C201.527 11.4411 200.728 10.6661 199.76 10.6661Z"
          fill="#6FA935"
        />
        <path
          d="M201.236 16.6229H198.282V28.5847H201.236V16.6229Z"
          fill="#6FA935"
        />
        <path
          d="M209.782 26.2362C207.894 26.2362 206.731 24.8318 206.731 22.5797C206.731 20.3277 207.99 18.8988 209.879 18.8988C211.284 18.8988 212.277 19.601 212.615 20.9571H215.569C215.255 18.0756 213.027 16.26 209.758 16.26C206.223 16.26 203.777 18.875 203.777 22.5797C203.777 26.2844 206.15 28.8995 209.782 28.8995C212.905 28.8995 215.23 26.9866 215.593 24.2016H212.615C212.3 25.5095 211.259 26.2354 209.782 26.2354V26.2362Z"
          fill="#6FA935"
        />
        <path
          d="M227.312 16.6229L227.143 18.463C226.416 17.0341 224.745 16.2592 222.953 16.2592C219.588 16.2592 217.336 18.705 217.336 22.4579C217.336 26.2108 219.37 28.8986 222.929 28.8986C224.866 28.8986 226.294 27.9545 227.046 26.7193L227.239 28.5839H230V16.6221H227.312V16.6229ZM223.607 26.2361C221.549 26.2361 220.29 24.7352 220.29 22.6042C220.29 20.4731 221.574 18.9477 223.632 18.9477C225.69 18.9477 226.997 20.4493 226.997 22.6042C226.997 24.759 225.666 26.2361 223.607 26.2361Z"
          fill="#6FA935"
        />
        <path
          d="M68.7614 26.6749L53.3614 0L30.2603 40.0189H45.6603L53.3706 26.6826L61.0626 40.0189H76.4626L68.7522 26.6826"
          fill="#6FA935"
        />
        <path
          d="M45.1203 0.937988H30.0802L22.5536 13.9718L15.0401 0.937988H0L15.0401 26.9873L7.51354 40.0211H22.5536L45.1203 0.937988Z"
          fill="#1D80AA"
        />
      </g>
      <defs>
        <clipPath id="clip0_38_4">
          <rect width="230" height="40.0204" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
