import styled from "@emotion/styled";
import React from "react";
import SVG from "../../../common/SVG";
import SuccessText from "../../../icons/text/SuccessText";
import Card from "./Card";
import { mobile } from "../../../styles/media";

const SuccessSectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem 10% 4rem 10%;
  background-color: ${({ theme }) => theme.colors.white};

  .text-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    h1 {
      display: flex;
      font-size: ${({ theme }) => theme.fontSizes.xxxxl};
      gap: 1rem;
      justify-content: center;
      align-items: center;
    }
  }
  .cards-container{
    display: flex;
    gap: 1rem;
    justify-content: center;
    ${mobile(`
    flex-direction: column;
`)}
  }
`;

const SuccessSection = () => {
  return (
    <SuccessSectionContainer>
      <div className="text-container">
        <h1>
          <span>Some</span>
          <SVG icon={<SuccessText />} width="200px" height="40px" />
        </h1>
        <h1>stories from Young Africa</h1>
      </div>
      <div className="cards-container">
        <Card
        boldText={"- Fatima, Software Developer, Nigeria"}
          label={`Young Africa helped me land my dream job in tech within weeks of signing up. I couldn't be happier with the opportunities it provided."`}
        />
        <Card
        boldText={"- Fatima, Software Developer, Nigeria"}
          label={`"I appreciate how Young africa simplified the job search process for me. Within days, I had multiple interview requests from reputable companies."
          - Samuel, Marketing Professional, Kenya
          `}
        />
        <Card
        boldText={"- Fatima, Software Developer, Nigeria"}
          label={`Young Africa helped me land my dream job in tech within weeks of signing up. I couldn't be happier with the opportunities it provided."
          `}
        />
      </div>
    </SuccessSectionContainer>
  );
};

export default SuccessSection;
