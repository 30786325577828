import styled from "@emotion/styled";
import React from "react";
import SVG from "../../../common/SVG";
import UsText from "../../../icons/text/UsText";
import { Link } from "react-router-dom";
import RightArrowIcon from "../../../icons/RightArrowIcon";
import Item from "./Item";
import { mobile } from "../../../styles/media";

const TopInfoSectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 4rem 10% 0 10%;
  ${mobile(`
    flex-direction: column;
    padding: 4rem 5% 0 5%;
  `)}
  .left-side {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    ${mobile(`
    width: 100%;
  `)}
    h1 {
      display: flex;
      align-items: center;
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      font-size: ${({ theme }) => theme.fontSizes.xxxxl};
      color: ${({ theme }) => theme.colors.text};
      ${({ theme }) =>
        mobile(`
        font-size: ${theme.fontSizes.xxl};
        white-space: nowrap;
      `)}
    }
    p {
      font-weight: ${({ theme }) => theme.fontWeights.light};
      font-size: ${({ theme }) => theme.fontSizes.md};
      color: ${({ theme }) => theme.colors.text};
    }
    a {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.text};
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      font-size: ${({ theme }) => theme.fontSizes.md};
    }
  }
  .right-side {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    ${mobile(`
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    overflow-x: auto;
  `)}
  }
`;

const TopInfoSection = () => {
  return (
    <TopInfoSectionContainer>
      <div className="left-side">
        <h1>
          <span>Why Choose</span>
          <SVG width="80px" height="40px" icon={<UsText />} />
        </h1>
        <p>
          <b>At Young Africa,</b> we are committed to revolutionizing the job
          search experience for Africans. Here's why thousands of job seekers
          trust us to help them find their next opportunity:
        </p>
        <Link to="/jobs">
          <span>Read more about us</span>
          <SVG width="17px" height="17px" icon={<RightArrowIcon />} />
        </Link>
      </div>
      <div className="right-side">
        <Item
          title="Tailored Job Recommendations"
          text="Text Text Text Text Text Text Text Text"
        />
        <Item
          title="Tailored Job Recommendations"
          text="Text Text Text Text Text Text Text Text"
        />
        <Item
          title="Tailored Job Recommendations"
          text="Text Text Text Text Text Text Text Text"
        />
        <Item
          title="Tailored Job Recommendations"
          text="Text Text Text Text Text Text Text Text"
        />
        <Item
          title="Tailored Job Recommendations"
          text="Text Text Text Text Text Text Text Text"
        />
        <Item
          title="Tailored Job Recommendations"
          text="Text Text Text Text Text Text Text Text"
        />
      </div>
    </TopInfoSectionContainer>
  );
};

export default TopInfoSection;
