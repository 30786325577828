import styled from "@emotion/styled";
import SVG from "../SVG";
import LogoIcon from "../../icons/LogoIcon";
import TwitterIcon from "../../icons/TwitterIcon";
import InstagramIcon from "../../icons/InstagramIcon";
import LinkedinIcon from "../../icons/LinkedinIcon";
import MailIcon from "../../icons/MailIcon";
import PhoneIcon from "../../icons/PhoneIcon";
import WindowIcon from "../../icons/WindowIcon";
import FooterIcon from "../../icons/FooterIcon";
import { mobile } from "../../styles/media";
import {useBreakpoint} from '../../hooks/use-Breakpoint'

const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.5fr 1.3fr 1fr;
  gap: 2rem;
  padding: 4rem 10% 4rem 10%;
  background-color: ${({ theme }) => theme.colors.primary};
  position: relative;
  ${mobile(`  
    grid-template-columns: 1fr;
  `)}
  .first-part {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    p {
      color: ${({ theme }) => theme.colors.white};
    }
    .social-media {
      display: flex;
      gap: 1rem;
      justify-content: start;
      align-items: center;
      width: 100%;
    }
  }
  .second-part {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  .third-part {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h3 {
      color: ${({ theme }) => theme.colors.white};
    }
    .split-part {
      display: flex;
      align-items: center;
      gap: 1rem;
      span {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
  .image-logo{
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

const Footer = () => {
  const {isMobile} = useBreakpoint();
  return (
    <FooterContainer>
      <div className="first-part">
        <h3>
          <SVG
            icon={<LogoIcon />}
            width="200px"
            height="40px"
            color={"white"}
          />
        </h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit? Lorem ipsum
          dolor sit amet, consectetur adipiscing elit?
        </p>
        <div className="social-media">
          <SVG icon={<TwitterIcon />} width="30px" height="30px" />
          <SVG icon={<InstagramIcon />} width="30px" height="30px" />
          <SVG icon={<LinkedinIcon />} width="30px" height="30px" />
        </div>
      </div>
      <div className="second-part">
        <span>Section</span>
        <span>Section</span>
        <span>Section</span>
        <span>Section</span>
      </div>
      <div className="third-part">
        <h3>Contact us:</h3>
        <div className="split-part">
          <SVG icon={<MailIcon />} width="20px" height="20px" />
          <span>info@youngafrica.com</span>
        </div>
        <div className="split-part">
          <SVG icon={<PhoneIcon />} width="20px" height="20px" />
          <span>348 9824 9237</span>
        </div>
        <div className="split-part">
          <SVG icon={<WindowIcon />} width="20px" height="20px" />
          <span>606 street Richmond, South Africa</span>
        </div>
      </div>
      {!isMobile && <div className="fourth-part" />}
      <div className="image-logo">
        <SVG icon={<FooterIcon />} width="100%" height="100%" />
      </div>
    </FooterContainer>
  );
};

export default Footer;
