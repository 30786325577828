import styled from "@emotion/styled";
import React from "react";
import SVG from "../SVG";
import LogoIcon from "../../icons/LogoIcon";
import { Link } from "react-router-dom";
import {mobile} from "../../styles/media";

const NavbarContainer = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1.5rem 0;
  position: relative;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    ${mobile(`
      position: absolute;
      bottom: 0;
    `)}
    li {
      padding: 0 1rem;
      color: ${({ theme }) => theme.colors.text};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      white-space: nowrap;
    }
  }
  .right-side{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    .sign-up{
      color: ${({ theme }) => theme.colors.text};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      white-space: nowrap;
    }
    .contact-us{
      color: ${({ theme }) => theme.colors.text};
      background-color: ${({ theme }) => theme.colors.white};
      padding: 0.3rem;
      box-shadow: ${({ theme }) => theme.boxShadow.button};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      white-space: nowrap;

    }
  }
`;

const Navbar = () => {
  return (
    <NavbarContainer>
        <Link to="/">
          <SVG icon={<LogoIcon />} width={"200px"} height={"50px"} cursor={"pointer"}/>
        </Link>
      <ul>
        <li>Why us?</li>
        <li>How it works</li>
        <li>Our process</li>
      </ul>
      <div className="right-side">
        <Link to="sign-up" className="sign-up">Sign up</Link>
        <Link to="contact-us" className="contact-us">Contact us</Link>
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
