import styled from "@emotion/styled"
import Rectangle from "../../../../../assets/images/Rectangle.png"
import { mobile } from "../../../../styles/media"

const CardContainer = styled.div`
    width: 400px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    ${mobile(`
        width: 100%;
`)}
    span{
        color: ${({theme}) => theme.colors.text};
    }
    img{
        width: 100%;
    }
`

const Card = ({label, boldText}) => {
  return (
    <CardContainer>
        <img src={Rectangle} alt="ImageNotFound" />
        <span>{label}</span>
        <b>{boldText}</b>
        </CardContainer>
  )
}

export default Card