export const theme = {
    colors: {
        primary: '#6FA935',
        secondary: '#1D80AA',
        white: '#fff',
        background: 'linear-gradient(180deg, #F7FFEF 0%, #F3FFE8 30%, #FFECDC 74.5%, #FFFFFF 100%)',
        text:'#2B2B2B',
        orange: '#D77F31',
        gray:"rgba(217, 217, 217, 1)",
        secondaryBackground: 'rgba(29, 128, 170, 0.04)',
    },
    fontSizes: {
        xxs: '0.5rem',
        xs: '0.75rem',
        sm: '0.875rem',
        md: '1rem',
        lg: '1.125rem',
        xl: '1.25rem',
        xxl: '1.5rem',
        xxxl: '2rem',
        xxxxl: '2.5rem',
    },  
    fontWeights: {
        thin: 100,
        light: 300,
        normal: 400,
        medium: 500,
        bold: 700,
        bolder: 900
    },
    boarderRadius: {
        xs: '0.125rem',
        sm: '0.25rem',
        md: '0.5rem',
        lg: '1rem',
        xl: '1.5rem',
        xxl: '2rem',  
        round: '50%'
    },
    boxShadow:{
        primary: '0px 4px 22px 0px #0000001F',
        button:'0px 4px 4px 0px #0000000F'
    }
}