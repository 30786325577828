import styled from "@emotion/styled";
import React, { useState } from "react";
import TopBar from "./TopBar";
import Navbar from "../../common/Navbar";
import Header from "./Header";
import HomePageHeader from "../../../assets/images/HomePageHeader.jpeg";
import TopInfoSection from "./TopInfoSection";
import BottomInfoSection from "./BottomInfoSection";
import FindJobSection from "./FindJobSection";
import SuccessSection from "./SuccessSection";
import BottomSection from "./BottomSection";
import Footer from "../../common/Footer";
import { mobile } from "../../styles/media";

const HomePageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .top-container {
    background: ${({ theme }) => theme.colors.background};
  }
  .image-container {
    width: 100%;
    height: 500px;
    position: relative;
    ${mobile(`
        height: 300px;
      `)}
    img {
      width: 700px;
      height: 360px;
      position: absolute;
      top: 55%;
      left: 50%;
      border-radius: ${({ theme }) => theme.boarderRadius.lg};
      transform: translate(-50%, -50%);
      ${mobile(`
        width: 90%;
        height: 300px;
      `)}
    }
  }
`;

const HomePage = () => {
  const [openTopBar, setOpenTopBar] = useState(true);
  const handleTopBar = () => {
    setOpenTopBar(false);
  };
  return (
    <HomePageContainer>
      {openTopBar && <TopBar handleTopBar={handleTopBar} />}
      <div className="top-container">
        <Navbar />
        <Header />
        <div className="image-container">
          <img src={HomePageHeader} alt="HomePageHeader" />
        </div>
      </div>
      <TopInfoSection />
      <br />
      <BottomInfoSection />
      <FindJobSection />
      <SuccessSection />
      <BottomSection />
      <Footer />
    </HomePageContainer >
  );
};

export default HomePage;
