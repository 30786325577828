import { Routes as Router, Route } from 'react-router-dom';
import HomePage from '../pages/Home';

const Routes = () => {
  return (
    <Router>
        <Route path="/" element={<HomePage />} />
      <Route path="/*" element={<h1>Page Not Found 404</h1>} />
    </Router>
  );
};

export default Routes;
