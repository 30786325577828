import React from 'react'
import GlobalStyles from './styles/Global'
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './router/Routes';
import { ThemeProvider } from '@emotion/react';
import { theme } from './styles/theme';

const AppWrapper = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Routes />
      </ThemeProvider>
    </Router>
  )
}

export default AppWrapper