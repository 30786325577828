import styled from "@emotion/styled";
import SVG from "../../../common/SVG";
import LogoTextIcon from "../../../icons/text/LogoTextIcon";
import { useTheme } from "@emotion/react";
import PrimaryButton from "../../../common/button/PrimaryButton";
import { mobile } from "../../../styles/media";

const BottomSectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 4rem 10% 4rem 10%;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  ${mobile(`
  gap: 2rem;
  `)}
  .text-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    h1 {
      display: flex;
      font-size: ${({ theme }) => theme.fontSizes.xxxxl};
      gap: 1rem;
      justify-content: center;
      align-items: center;
      ${({ theme }) => mobile(`
        font-size: ${theme.fontSizes.xxl};
        gap: 0rem;
      `)}
    }
  }
`;

const BottomSection = () => {
  const theme = useTheme();
  return (
    <BottomSectionContainer>
      <div className="text-container">
        <h1>Change your life today</h1>

        <h1>
          <span>with</span>
          <SVG
            icon={<LogoTextIcon />}
            width="250px"
            height="40px"
            color={theme.colors.orange}
            mobileHeight={"20px"}
            mobileWidth={"200px"}
          />
        </h1>

      </div>
      <PrimaryButton
          label="Sign up for free"
          width="140px"
          height="37px"
          color={theme.colors.white}
          background={theme.colors.orange}
          boarderRadius={theme.boarderRadius.sm}
        />
    </BottomSectionContainer>
  );
};

export default BottomSection;
