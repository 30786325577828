import styled from "@emotion/styled";

const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: ${({ active }) => active ? "rgba(255, 255, 255, 0.12)" : "transparent"};
  padding: 1rem;
  border-radius: ${({theme}) => theme.boarderRadius.md};
  .checkbox{
    width: 20px;
    height: 20px;
    background-color: ${({theme}) => theme.colors.gray};
    border-radius: ${({theme}) => theme.boarderRadius.xs};
  }
  span{
    color: ${({theme}) => theme.colors.white};
  }
`;

const Item = ({ label, active }) => {
  return (
    <ItemContainer active={active}>
      <div className="checkbox" />
      <span>{label}</span>
    </ItemContainer>
  );
};

export default Item;
