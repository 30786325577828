import React from "react";

const InstagramIcon = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18.5" cy="18.5" r="18.5" fill="white" />
      <path
        d="M18 15.75C17.138 15.75 16.3114 16.0924 15.7019 16.7019C15.0924 17.3114 14.75 18.138 14.75 19C14.75 19.862 15.0924 20.6886 15.7019 21.2981C16.3114 21.9076 17.138 22.25 18 22.25C18.862 22.25 19.6886 21.9076 20.2981 21.2981C20.9076 20.6886 21.25 19.862 21.25 19C21.25 18.138 20.9076 17.3114 20.2981 16.7019C19.6886 16.0924 18.862 15.75 18 15.75Z"
        fill="#6FA935"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7701 10.0819C16.2461 9.69664 19.754 9.69664 23.2301 10.0819C25.1291 10.2939 26.6601 11.7889 26.8831 13.6949C27.2953 17.2196 27.2953 20.7803 26.8831 24.3049C26.6601 26.2109 25.1291 27.7059 23.2311 27.9189C19.7547 28.3043 16.2464 28.3043 12.7701 27.9189C10.8711 27.7059 9.34007 26.2109 9.11707 24.3059C8.70479 20.781 8.70479 17.2199 9.11707 13.6949C9.34007 11.7889 10.8711 10.2939 12.7701 10.0819ZM23.0001 12.9999C22.7348 12.9999 22.4805 13.1053 22.293 13.2928C22.1054 13.4804 22.0001 13.7347 22.0001 13.9999C22.0001 14.2652 22.1054 14.5195 22.293 14.707C22.4805 14.8946 22.7348 14.9999 23.0001 14.9999C23.2653 14.9999 23.5196 14.8946 23.7072 14.707C23.8947 14.5195 24.0001 14.2652 24.0001 13.9999C24.0001 13.7347 23.8947 13.4804 23.7072 13.2928C23.5196 13.1053 23.2653 12.9999 23.0001 12.9999ZM13.2501 18.9999C13.2501 17.7402 13.7505 16.532 14.6413 15.6412C15.5321 14.7504 16.7403 14.2499 18.0001 14.2499C19.2598 14.2499 20.468 14.7504 21.3588 15.6412C22.2496 16.532 22.7501 17.7402 22.7501 18.9999C22.7501 20.2597 22.2496 21.4679 21.3588 22.3587C20.468 23.2495 19.2598 23.7499 18.0001 23.7499C16.7403 23.7499 15.5321 23.2495 14.6413 22.3587C13.7505 21.4679 13.2501 20.2597 13.2501 18.9999Z"
        fill="#6FA935"
      />
    </svg>
  );
};

export default InstagramIcon;
