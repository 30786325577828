import React from "react";

const LinkedinIcon = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18.5" cy="18.5" r="18.5" fill="white" />
      <path
        d="M25 10C25.5304 10 26.0391 10.2107 26.4142 10.5858C26.7893 10.9609 27 11.4696 27 12V26C27 26.5304 26.7893 27.0391 26.4142 27.4142C26.0391 27.7893 25.5304 28 25 28H11C10.4696 28 9.96086 27.7893 9.58579 27.4142C9.21071 27.0391 9 26.5304 9 26V12C9 11.4696 9.21071 10.9609 9.58579 10.5858C9.96086 10.2107 10.4696 10 11 10H25ZM24.5 25.5V20.2C24.5 19.3354 24.1565 18.5062 23.5452 17.8948C22.9338 17.2835 22.1046 16.94 21.24 16.94C20.39 16.94 19.4 17.46 18.92 18.24V17.13H16.13V25.5H18.92V20.57C18.92 19.8 19.54 19.17 20.31 19.17C20.6813 19.17 21.0374 19.3175 21.2999 19.5801C21.5625 19.8426 21.71 20.1987 21.71 20.57V25.5H24.5ZM12.88 15.56C13.3256 15.56 13.7529 15.383 14.0679 15.0679C14.383 14.7529 14.56 14.3256 14.56 13.88C14.56 12.95 13.81 12.19 12.88 12.19C12.4318 12.19 12.0019 12.3681 11.685 12.685C11.3681 13.0019 11.19 13.4318 11.19 13.88C11.19 14.81 11.95 15.56 12.88 15.56ZM14.27 25.5V17.13H11.5V25.5H14.27Z"
        fill="#6FA935"
      />
    </svg>
  );
};

export default LinkedinIcon;
