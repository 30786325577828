import styled from '@emotion/styled'
import React from 'react'
import { mobile } from '../../../../styles/media'

const ItemContainer = styled.div`
    width: 200px;
    padding: 1rem;
    box-shadow: ${({theme}) => theme.boxShadow.primary};
    ${mobile(`
      width: 100% !important;
    `)}
`

const Item = ({label}) => {
  return (
    <ItemContainer>{label}</ItemContainer>
  )
}

export default Item