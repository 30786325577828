import React from "react";

const RightArrowIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0859 2.46875L17.1172 9.5L10.0859 16.5312M16.1406 9.5L1.88281 9.5"
        stroke="#6FA935"
        stroke-width="2.34375"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
};

export default RightArrowIcon;
