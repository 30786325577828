import React from "react";

const InfoDecorationPoints = () => {
  return (
    <svg
      width="60"
      height="320"
      viewBox="0 0 60 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 0.5V6.63462H1.5V0.5H0.5ZM0.5 18.9038V31.1731H1.5V18.9038H0.5ZM0.5 43.4423V55.7115H1.5V43.4423H0.5ZM0.5 67.9808V80.25H1.5V67.9808H0.5ZM0.5 92.5192V104.788H1.5V92.5192H0.5ZM0.5 117.058V129.327H1.5V117.058H0.5ZM0.5 141.596V153.865H1.5V141.596H0.5ZM0.5 166.135V178.404H1.5V166.135H0.5ZM0.5 190.673V202.942H1.5V190.673H0.5ZM0.5 215.212V227.481H1.5V215.212H0.5ZM0.5 239.75V252.019H1.5V239.75H0.5ZM0.5 264.288V276.558H1.5V264.288H0.5ZM0.5 288.827V301.096H1.5V288.827H0.5ZM0.5 313.365V319.5H1V320H8.3125V319H1.5V313.365H0.5ZM22.9375 320H37.5625V319H22.9375V320ZM52.1875 320H59.5V319H52.1875V320Z"
        fill="black"
      />
      <path d="M1 239H59.5" stroke="black" strokeDasharray="12 12" />
    </svg>
  );
};

export default InfoDecorationPoints;
