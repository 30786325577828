import styled from "@emotion/styled";
import SVG from "../../../common/SVG";
import SimpleProjectText from "../../../icons/text/SimpleProjectText";
import { useTheme } from "@emotion/react";
import Rectangle from "../../../../assets/images/Rectangle.png";
import Item from "./Item";
import PrimaryButton from "../../../common/button/PrimaryButton";
import Group from "../../../../assets/images/Group.png";
import { mobile } from "../../../styles/media";

const FindJobSectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem 10% 4rem 10%;
  background-color: ${({ theme }) => theme.colors.primary};
  position: relative;

  h1 {
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    flex-direction: column;
    ${mobile(`
      white-space: nowrap;  
`)}
  }
  p {
    width: 500px;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.light};
    line-height: 1.5rem;
    font-size: ${({ theme }) => theme.fontSizes.lg};
    ${mobile(`
      width: 100%;  
`)}
  }
  .main-content {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 2rem;
    ${mobile(`
      grid-template-columns: 90%;
`)}
    .left-side {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    .right-side {
      img {
        height: 400px;
        ${mobile(`
        width: 100%;
        height: 200px;
`)}
      }

    }
  }
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    background-image: url(${Group});
    height: 20px;
    bottom: 0.5rem;
  }
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    background-image: url(${Group});
    height: 20px;
    bottom: 2.3rem;
  }
`;

const FindJobSection = () => {
  const theme = useTheme();
  return (
    <FindJobSectionContainer>
      <h1>
        <span>Find your job through</span>
        <SVG
          width="400px"
          height="40px"
          icon={<SimpleProjectText />}
          color={theme.colors.white}
          mobileWidth="250px"
          mobileHeight="30px"
        />
      </h1>
      <p>
        You dont have to re-fill your information every time you sign up,
        instead you can fill it once and keep improving
      </p>
      <div className="main-content">
        <div className="left-side">
          <Item
            label="You don't have to re-fill your information every time you sign up."
            active={true}
          />
          <Item
            label="You don't have to re-fill your information every time you sign up."
            active={false}
          />
          <Item
            label="You don't have to re-fill your information every time you sign up."
            active={false}
          />
          <Item
            label="You don't have to re-fill your information every time you sign up."
            active={false}
          />
        </div>
        <div className="right-side">
          <img src={Rectangle} alt="ImageNotFound" />
        </div>
      </div>
      <PrimaryButton
        label="Sign up for free"
        width="140px"
        height="37px"
        color={theme.colors.primary}
        background={theme.colors.white}
        boarderRadius={theme.boarderRadius.sm}
      />

      <div className="decorated-line" />
    </FindJobSectionContainer>
  );
};

export default FindJobSection;
