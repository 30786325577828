import styled from '@emotion/styled'
import React from 'react'

const PrimaryButtonContainer = styled.button`
    width: ${({width}) => width};
    height: ${({height}) => height};
    background-color: ${({background}) => background};
    color: ${({color}) => color};
    border-radius: ${({boarderRadius}) => boarderRadius};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    border: none;
` 

const PrimaryButton = ({height, width, color, background, label, boarderRadius}) => {
  return (
    <PrimaryButtonContainer height={height} width={width} color={color} background={background} boarderRadius={boarderRadius}> {label}</PrimaryButtonContainer>
  )
}

export default PrimaryButton