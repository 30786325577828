import styled from '@emotion/styled'
import React, { useState } from 'react'
import SVG from '../../../common/SVG'
import CloseIcon from '../../../icons/CloseIcon'
import { useTheme } from '@emotion/react'

const TopBarContainer = styled.div`
    width: 100%;
    height: 45px;
    background-color: ${({theme}) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;
    h4{
        color: ${({theme}) => theme.colors.white};
        font-weight: ${({theme}) => theme.fontWeights.light};
        font-size: ${({theme}) => theme.fontSizes.sm};
    }
`

const TopBar = ({handleTopBar}) => {
    const theme = useTheme()
  return (
    <TopBarContainer>
        <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h4>
        <SVG width="25px" height="25px" icon={<CloseIcon />} stockColor={theme.colors.white} cursor={"pointer"} onClick={() => handleTopBar()} />
    </TopBarContainer>
  )
}

export default TopBar