import styled from "@emotion/styled";
import React from "react";
import SVG from "../../../common/SVG";
import LogoTextIcon from "../../../icons/text/LogoTextIcon";
import PrimaryButton from "../../../common/button/PrimaryButton";
import { useTheme } from "@emotion/react";
import { mobile } from "../../../styles/media";

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 4rem;
  .top-text {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.text};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    padding: 0.2rem 1rem;
    border-radius: ${({ theme }) => theme.boarderRadius.lg};
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    ${mobile(`
      gap: 0rem;
    `)}
    h1 {
      color: ${({ theme }) => theme.colors.text};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      font-size: ${({ theme }) => theme.fontSizes.xxxxl};
      height: 20px;
      ${({ theme }) =>
        mobile(` 

        font-size: ${theme.fontSizes.lg} !important;
        `)}
    }
    .split-title {
      display: flex;
      gap: 1rem;
      justify-content: center;
      ${mobile(` 
        margin-top:-0.4rem;
        gap: 0rem;
      `)}
    }
  }
  p {
    width: 780px;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.lg};
    ${({ theme }) =>
      mobile(`

        font-size: ${theme.fontSizes.md} !important;
        width: 90%;
    `)}
  }
`;

const Header = () => {
  const theme = useTheme();
  return (
    <HeaderContainer>
      <div className="top-text">Find your future job</div>
      <div className="title">
        <h1>Join thousands of Africans Building</h1> <br />
        <div className="split-title">
          <h1>their careers with</h1>
          <SVG
            width="300px"
            height="70px"
            icon={<LogoTextIcon />}
            mobileHeight={"20px"}
            mobileWidth={"200px"}
          />
        </div>
      </div>

      <p>
        Whether you're a recent graduate, a seasoned professional, or someone
        looking to switch careers, or maybe learn something new, we have all the
        opportunities for you. Our platform connects you with top employers
        across various industries in Africa.
      </p>
      <PrimaryButton
        label="Sign up for free"
        width="140px"
        height="40px"
        color={theme.colors.white}
        background={theme.colors.primary}
        boarderRadius={theme.boarderRadius.sm}
      />
    </HeaderContainer>
  );
};

export default Header;
